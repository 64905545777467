import { useState, useRef, MouseEventHandler, RefObject, useEffect } from "react"
import { XMarkIcon } from "@heroicons/react/24/outline"
import { useOutsideClick } from "common/hooks/interaction/useOutsideClick"
import { Heading } from "common/components/Heading"
import { useTranslations } from "modules/i18n/hooks/useTranslations"

interface PersonProps {
  avatar: string
  name: string
  caption: string
}

interface DonationDialogProps {
  title: string
  body: string
  donateText: string
  person: PersonProps
  onClose: Function
  onClick: MouseEventHandler<HTMLButtonElement>
  creativeRef: RefObject<HTMLDivElement>
}

export const DonationDialog = ({
  title,
  body,
  donateText,
  person,
  onClose,
  onClick,
  creativeRef,
}: DonationDialogProps) => {
  const [open, setOpen] = useState(true)
  const modalRef = useRef<HTMLDivElement>(null)
  const t = useTranslations("promos")

  useEffect(() => {
    document.body.classList.toggle("overflow-hidden", open)
  }, [open])

  useOutsideClick(modalRef, () => {
    setOpen(false)
    onClose()
  })

  const handleClose = () => {
    setOpen(false)
    onClose()
  }

  if (!open) {
    return null
  }

  return (
    <div
      className="relative"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      data-testid="donation-dialog"
    >
      <div
        className="fixed inset-0 bg-black opacity-50 transition-opacity"
        data-testid="backdrop"
      ></div>

      <div className="fixed inset-0 overflow-y-auto">
        <div
          className="flex min-h-full items-start justify-center p-4 text-center text-white sm:items-center sm:p-0"
          ref={creativeRef}
        >
          <div
            className="relative mt-8 overflow-y-visible rounded-md bg-red-500 px-4 pb-4 pt-5 shadow-xl sm:my-16 sm:w-full sm:max-w-lg sm:p-8"
            ref={modalRef}
            data-testid="modal"
          >
            {/* Avatar */}
            <div className="absolute inset-x-0 -top-8 mx-auto w-16">
              {/* We're leaving the alt empty, as avatar is for decorative purposes only */}
              <img src={person.avatar} alt="" className="rounded-full" />
            </div>

            <div className="absolute right-0 top-0 pr-4 pt-4">
              <button
                type="button"
                role="button"
                aria-label={t("button.close")}
                onClick={handleClose}
              >
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>

            <div className="mt-3 sm:mt-0">
              <div id="modal-title" className="mt-12">
                <Heading level={4}>{title}</Heading>
              </div>

              <div className="mt-2">
                <p>{body}</p>
              </div>

              <hr className="mx-auto my-4 h-px w-5 border-none bg-white" />

              <p>{person.name}</p>
              <p className="text-xs leading-3">{person.caption}</p>
            </div>

            <div className="mb-8 mt-4">
              <button
                className="mt-3 inline-flex rounded bg-white px-10 py-2 font-medium text-black hover:bg-gray-50"
                onClick={onClick}
              >
                {donateText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
