import { useContext, createContext } from "react"
import { useRouter as useNextRouter, NextRouter } from "next/router"

export const TestRouterContext = createContext<any>(null)

const ROUTES_PREFIX = "/pb"
export const HOME_PAGE_SLUG = "home-page"
export const DEFAULT_HOST = "https://theconversation.com"

export const pageUrl = {
  edit: ({ regionCode, pageSlug }: { regionCode: string; pageSlug: string }) =>
    `${ROUTES_PREFIX}/${regionCode}/${pageSlug}/edit`,
  /**
   * Returns a URL to the page view.
   * @param regionCode
   * @param pageSlug
   * @param timestamp - optional timestamp to bypass cache. It'll add a query param `v` with the timestamp value.
   * @param prefix - whether to prepend the `/pb` routes prefix
   * @param host - optional host to use in the URL, including its protocol (e.g.: "https://theconversation.com")
   */
  view: ({
    regionCode,
    pageSlug,
    timestamp,
    prefix = true,
    host = "",
  }: {
    regionCode: string
    pageSlug: string
    timestamp?: string
    prefix?: boolean
    host?: string
  }) => {
    const region = regionCode ? `/${regionCode}` : ""
    const slug = pageSlug ? `/${pageSlug}` : ""
    const queryString = timestamp ? `?v=${timestamp}` : ""
    const pathParts = prefix ? [ROUTES_PREFIX, region, slug] : [region, slug]
    const path = pathParts.join("")

    return host + path + queryString
  },
}

/**
 * Tiny wrapper around native NextJS useRouter from next/router.
 * It allows for easier testing, especially for Storyshots.
 *
 * If you plan to test component which uses `useRouter` you can wrap
 * it with `<TestRouterContext.Provider value={}>` and provide router
 * value through context provider.
 * @returns router
 */
export const useRouter = () => {
  const router = useNextRouter()
  const testRouter: NextRouter = useContext(TestRouterContext)

  return testRouter || router
}
