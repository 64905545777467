import {
  Block,
  BlockEnum,
  BlockquoteBlock,
  BodyBlock,
  CallToActionBlock,
  ContentSummaryBlock,
  CustomUrlBlock,
  EventsBlock,
  GridBlock,
  HeadingBlock,
  IframeBlock,
  ImageBlock,
  JobsBoardBlock,
  LinkBlock,
  ListBlock,
  ListItemBlock,
  MostReadBlock,
  ParagraphBlock,
  PartnersBlock,
  TextBlock,
  VideoBlock,
} from "common/types/graphql"

import type { GridProps } from "modules/editor/blocks/Grid"
import type { ContentSummaryFrontendBlock } from "modules/editor/blocks/ContentSummary"
import type { CustomUrlBlockFrontendBlock } from "modules/editor/blocks/CustomUrlBlock"

/** Image alignment string */
export type ImageAlign = "left" | "right" | "top"

/** Image aspect ratio string */
export enum ImageAspectRatio {
  Square = "1:1",
  Widescreen = "16:9",
  Ultrawide = "21:9",
}

/** FrontendBlock represents blocks which props exist only in frontend */
export type FrontendBlock =
  | Block
  | GridProps
  | ContentSummaryFrontendBlock
  | CustomUrlBlockFrontendBlock
  | null

export function isBlockquoteBlock(block: Block | null): block is BlockquoteBlock {
  return !!block && block.type === BlockEnum.Blockquote
}

export function isBodyBlock(block: Block | null): block is BodyBlock {
  return !!block && block.type === BlockEnum.Body
}

export function isCallToActionBlock(block: Block | null): block is CallToActionBlock {
  return !!block && block.type === BlockEnum.CallToAction
}

export function isContentSummaryBlock(block: Block | null): block is ContentSummaryBlock {
  return !!block && block.type === BlockEnum.ContentSummary
}

export function isEventsBlock(block: Block | null): block is EventsBlock {
  return !!block && block.type === BlockEnum.Events
}

export function isGridBlock(block: Block | null): block is GridBlock {
  return !!block && block.type === BlockEnum.Grid
}

export function isHeadingBlock(block: Block | null): block is HeadingBlock {
  return !!block && block.type === BlockEnum.Heading
}

export function isIframeBlock(block: Block | null): block is IframeBlock {
  return !!block && block.type === BlockEnum.Iframe
}

export function isImageBlock(block: Block | null): block is ImageBlock {
  return !!block && block.type === BlockEnum.Image
}

export function isLineBreakBlock(block: Block | null): block is Block {
  return !!block && block.type === BlockEnum.LineBreak
}

export function isLinkBlock(block: Block | null): block is LinkBlock {
  return !!block && block.type === BlockEnum.Link
}

export function isListBlock(block: Block | null): block is ListBlock {
  return !!block && block.type === BlockEnum.List
}

export function isListItemBlock(block: Block | null): block is ListItemBlock {
  return !!block && block.type === BlockEnum.ListItem
}

export function isParagraphBlock(block: Block | null): block is ParagraphBlock {
  return !!block && block.type === BlockEnum.Paragraph
}

export function isTextBlock(block: Block | null): block is TextBlock {
  return !!block && block.type === BlockEnum.Text
}

export function isVideoBlock(block: Block | null): block is VideoBlock {
  return !!block && block.type === BlockEnum.Video
}

export function isPartnersBlock(block: Block | null): block is PartnersBlock {
  return !!block && block.type === BlockEnum.Partners
}

export function isMostReadBlock(block: Block | null): block is MostReadBlock {
  return !!block && block.type === BlockEnum.MostRead
}

export function isCustomUrlBlock(block: Block | null): block is CustomUrlBlock {
  return !!block && block.type === BlockEnum.CustomUrl
}

export function isJobsBoardBlock(block: Block | null): block is JobsBoardBlock {
  return !!block && block.type === BlockEnum.JobsBoard
}
