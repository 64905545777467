import dynamic from "next/dynamic"
import type { BlockquoteProps } from "modules/editor/blocks/Blockquote"
import type { BodyProps } from "modules/editor/blocks/Body"
import type { CallToActionProps } from "modules/editor/blocks/CallToAction"
import type { EventsProps } from "modules/editor/blocks/Events"
import type { HeadingProps } from "modules/editor/blocks/Heading"
import type { IframeProps } from "modules/editor/blocks/Iframe"
import type { ImageProps } from "modules/editor/blocks/Image"
import type { ListProps } from "modules/editor/blocks/List"
import type { ParagraphProps } from "modules/editor/blocks/Paragraph"
import type { VideoProps } from "modules/editor/blocks/Video"
import type { LinkProps } from "modules/editor/blocks/Link"
import type { TextProps } from "modules/editor/blocks/Text"
import {
  FrontendBlock,
  isBlockquoteBlock,
  isBodyBlock,
  isCallToActionBlock,
  isContentSummaryBlock,
  isCustomUrlBlock,
  isEventsBlock,
  isGridBlock,
  isHeadingBlock,
  isIframeBlock,
  isImageBlock,
  isJobsBoardBlock,
  isLineBreakBlock,
  isLinkBlock,
  isListBlock,
  isMostReadBlock,
  isParagraphBlock,
  isPartnersBlock,
  isTextBlock,
  isVideoBlock,
} from "modules/editor/types"
import { GridProps } from "../Grid"
import { PartnersProps } from "modules/editor/blocks/Partners"
import { ContentSummaryFrontendBlock } from "modules/editor/blocks/ContentSummary"
import { CustomUrlBlockFrontendBlock } from "modules/editor/blocks/CustomUrlBlock"
import { MostReadProps } from "modules/editor/blocks/MostRead"
import { JobsBoardProps } from "modules/editor/blocks/JobsBoard"

const Blockquote = dynamic<BlockquoteProps>(() =>
  import("modules/editor/blocks/Blockquote").then((mod) => mod.Blockquote),
)
const Body = dynamic<BodyProps>(() => import("modules/editor/blocks/Body").then((mod) => mod.Body))
const CallToAction = dynamic<CallToActionProps>(() =>
  import("modules/editor/blocks/CallToAction").then((mod) => mod.CallToAction),
)
const ContentSummary = dynamic<ContentSummaryFrontendBlock>(() =>
  import("modules/editor/blocks/ContentSummary").then((mod) => mod.ContentSummary),
)
const Events = dynamic<EventsProps>(() =>
  import("modules/editor/blocks/Events").then((mod) => mod.Events),
)
const Grid = dynamic<GridProps>(() => import("modules/editor/blocks/Grid").then((mod) => mod.Grid))
const Heading = dynamic<HeadingProps>(() =>
  import("modules/editor/blocks/Heading").then((mod) => mod.Heading),
)
const Iframe = dynamic<IframeProps>(() =>
  import("modules/editor/blocks/Iframe").then((mod) => mod.Iframe),
)
const Image = dynamic<ImageProps>(() =>
  import("modules/editor/blocks/Image").then((mod) => mod.Image),
)
const Link = dynamic<LinkProps>(() => import("modules/editor/blocks/Link").then((mod) => mod.Link))
const List = dynamic<ListProps>(() => import("modules/editor/blocks/List").then((mod) => mod.List))
const Paragraph = dynamic<ParagraphProps>(() =>
  import("modules/editor/blocks/Paragraph").then((mod) => mod.Paragraph),
)
const Text = dynamic<TextProps>(() => import("modules/editor/blocks/Text").then((mod) => mod.Text))
const Video = dynamic<VideoProps>(() =>
  import("modules/editor/blocks/Video").then((mod) => mod.Video),
)
const Partners = dynamic<PartnersProps>(() =>
  import("modules/editor/blocks/Partners").then((mod) => mod.Partners),
)
const MostRead = dynamic<MostReadProps>(() =>
  import("modules/editor/blocks/MostRead").then((mod) => mod.MostRead),
)
const CustomUrlBlock = dynamic<CustomUrlBlockFrontendBlock>(() =>
  import("modules/editor/blocks/CustomUrlBlock").then((mod) => mod.CustomUrlBlock),
)
const JobsBoard = dynamic<JobsBoardProps>(() =>
  import("modules/editor/blocks/JobsBoard").then((mod) => mod.JobsBoard),
)

interface BlocksRendererProps {
  blocks: FrontendBlock[]
}

interface BlockComponentProps {
  block: FrontendBlock
}

export const BlockComponent = ({ block }: BlockComponentProps) => {
  if (isBlockquoteBlock(block)) {
    return <Blockquote {...block.props} data-testid={block.type} />
  } else if (isBodyBlock(block)) {
    return <Body {...block.props} data-testid={block.type} />
  } else if (isCallToActionBlock(block)) {
    return <CallToAction {...block.props} data-testid={block.type} />
  } else if (isEventsBlock(block)) {
    return <Events {...block} data-testid={block.type} />
  } else if (isGridBlock(block)) {
    return <Grid {...block} data-testid={block.type} />
  } else if (isContentSummaryBlock(block)) {
    return <ContentSummary {...block} data-testid={block.type} />
  } else if (isHeadingBlock(block)) {
    return <Heading {...block.props} data-testid={block.type} />
  } else if (isIframeBlock(block)) {
    return <Iframe {...block.props} data-testid={block.type} />
  } else if (isImageBlock(block)) {
    const { alt, ...props } = block.props
    return <Image alt={alt} {...props} data-testid={block.type} />
  } else if (isLinkBlock(block)) {
    return <Link {...block.props} data-testid={block.type} />
  } else if (isListBlock(block)) {
    return <List {...block.props} data-testid={block.type} />
  } else if (isParagraphBlock(block)) {
    return <Paragraph {...block.props} data-testid={block.type} />
  } else if (isTextBlock(block)) {
    return <Text {...block.props} data-testid={block.type} />
  } else if (isVideoBlock(block)) {
    return <Video {...block.props} data-testid={block.type} />
  } else if (isPartnersBlock(block)) {
    return <Partners partners={block.partners} {...block.props} data-testid={block.type} />
  } else if (isMostReadBlock(block)) {
    return <MostRead {...block} data-testid={block.type} />
  } else if (isCustomUrlBlock(block)) {
    return <CustomUrlBlock {...block} data-testid={block.type} />
  } else if (isJobsBoardBlock(block)) {
    return <JobsBoard {...block} data-testid={block.type} />
  } else if (isLineBreakBlock(block)) {
    return <br />
  } else {
    return null
  }
}

export const BlocksRenderer = ({ blocks }: BlocksRendererProps) => {
  return (
    <>
      {blocks.map((block, index) => (
        <BlockComponent block={block} key={index} />
      ))}
    </>
  )
}
